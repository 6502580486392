import {DateTime} from "luxon"
import clsx from "clsx"
import {PageLayout} from "~/components/PageLayout"

export const Footer = () => {

    const now = DateTime.now()

    return (
        <div
            className={clsx(
                "sticky top-[100vh] mt-20 bg-gray-50"
            )}
        >
            <PageLayout>
                <div
                    className="pt-10 pb-16 flex items-start justify-end"
                >
                    <p className="text-sm text-gray-400">
                        {`© ${now.toLocaleString({year: "numeric"})} Ben De La Haye. All rights reserved.`}
                    </p>
                </div>
            </PageLayout>
        </div>
    )
}