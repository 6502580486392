import {Outlet} from "@remix-run/react"
import {useRef, useState} from "react"
import {useScrollPosition} from "~/hooks/useScrollPosition"
import {Footer} from "~/components/Footer";
import {NavToolbar} from "~/components/NavToolbar"

export default function Index() {

  const [isScrolling, setIsScrolling] = useState(false)
  const containerRef = useRef<HTMLDivElement | null>(null)

  useScrollPosition(({currPos}) => {
    setIsScrolling(currPos.y !== 0)
  }, undefined, containerRef, undefined, undefined)

  return (
    <>
      <NavToolbar style={isScrolling ? "elevated" : "default"}/>
      <div className="w-full overflow-y-scroll">
        <div className="w-full" ref={containerRef}>
          <Outlet/>
          <Footer/>
        </div>
      </div>
    </>
  )
}